import React, { useRef, useState } from 'react'
import PT from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import 'swiper/components/pagination/pagination.scss'
import classNames from 'classnames'

import ButtonsSlider from '../ButtonsSlider'
import SvgSprite from 'src/components/SvgSprite'
import SiteTypesModal from '../SiteTypesModal'
import AppTypesModal from '../AppTypesModal'
import PackageTypesModal from '../PackageTypesModal'
import OurServicesModal from '../OurServicesModal'


import styles from './styles.module.scss'

const slidesContent = [
    {
        title: 'Branding',
        textDesktop:
            'Each branding project begins with an audit of your key messaging, visual language, and strategy. We then craft a cohesive brand identity that’s true to your mission, vision, and values. Calaton Studio offers three brand identity development packages, each of which is tailored to suit the unique goals and scale of your business.',
        textMobile:
            'The most important part for any startup. Each project begins with a search  and determination of its face. Calaton Studio offers three identity development packages, depending on the tasks and scale of the business.',
        button: 'Packages types',
    },
    {
        title: 'Web Design',
        textDesktop:
            'A good website makes your user journey easy — but a great website makes that journey enjoyable. Your website should solve your customers’ problems and invite them in to take action. We work with you to craft a website that aligns exactly with your business goals to captivate audiences and convert.',
        textMobile:
            'The site should solve tasks, be the pride of the company or facilitate routine work, from receiving applications to downloading data, not cause inconvenience when adjusting and changing content, become interesting for your users, customers, make  an impression.',
        button: 'Site types',
    },
    {
        title: 'Mobile Design',
        textDesktop:
            'Don’t let the massive mobile market go untapped. We’ll help you create software for smartphones, tablets, and other mobile devices that connects you to customers with convenience and creativity.',
        textMobile:
            'Mobile application - software designed to work on smartphones, tablets and other mobile devices. It gives users the opportunity to solve their problems in a convenient and even playful way. A well-made mobile application increases the credibility of the company and its rating among similar companies.',
        button: 'App types',
    },
    {
        title: 'Graphic Design',
        textDesktop: `We do not just "do beautifully", we take into account the client's business goals and work to achieve them. We use a proven brand design model and take a comprehensive approach to the development of design and visual communication.`,
        textMobile: `We do not just "do beautifully", we take into account the client's business goals and work to achieve them. We use a proven brand design model and take a comprehensive approach to the development of design and visual communication.`,
        button: 'Our servises',
    },
]

SwiperCore.use([EffectFade, Pagination])

const ServicesSlider = props => {
    const servicesTypes = useRef(null)

    const [activeModal, setActiveModal] = useState(null)

    const parameters = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            bulletActiveClass: styles.active,
            bulletClass: styles.bullet,
        },
        effect: props.effect,
    }

    const buttonsSliderContainer = classNames(styles.buttonsSlider, 'container')
    const noSwipingSwiperClass = classNames(styles.swiper, props.noSwiping)
    const slideContainerClass = classNames(styles.content, 'container')
    const pagination = classNames(styles.pagination, 'swiper-pagination swiper-pagination-bullets ')
    const backgroundImg = classNames(styles.background, 'container')

    const changeSlide = swiper => {
        servicesTypes.current.swiper.slideTo(swiper.activeIndex)
    }

    const closeModal = () => {
        setActiveModal(null)
    }

    return (
        <>
            <ButtonsSlider
                className={buttonsSliderContainer}
                classNameSlide={styles.buttonsSliderSlide}
                slides={slidesContent}
                onClick={changeSlide}
            />
            <Swiper ref={servicesTypes} className={noSwipingSwiperClass} {...parameters}>
                {slidesContent.map(({ title, textDesktop, textMobile, button}) => (
                    <SwiperSlide key={title} className={styles.slide}>
                        <div className={slideContainerClass}>
                            <div className={styles.wrapper}>
                                <div>
                                    <h3 className={styles.title}>{title}</h3>
                                    <p className={styles.textDesktop}>{textDesktop}</p>
                                    <p className={styles.textMobile}>{textMobile}</p>
                                </div>
                                <div className={styles.endLine}>
                                    <button
                                        className={styles.typesBtn}
                                        type="button"
                                        onClick={() => setActiveModal(button)}
                                    >
                                        {button}
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <div className={pagination}></div>
                <div className={backgroundImg}></div>
            </Swiper>
            {activeModal === 'Site types' && <SiteTypesModal closeModal={closeModal} />}
            {activeModal === 'App types' && <AppTypesModal closeModal={closeModal} />}
            {activeModal === 'Packages types' && <PackageTypesModal closeModal={closeModal} />}
            {activeModal === 'Our servises' && <OurServicesModal closeModal={closeModal} />}
        </>
    )
}

export default ServicesSlider

ServicesSlider.propTypes = {
    effect: PT.string,
    noSwiping: PT.string,
}
