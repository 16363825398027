import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'

import styles from './styles.module.scss'

const Address = () => {
    const classContainer = classNames(styles.container, 'container')

    return (
        <section className={styles.sectionAddress}>
            <div className={classContainer}>
                <div className={styles.wrap}>
                    <div className={styles.logoBlock}>
                        <SvgSprite name="calatonStudio" className={styles.calatonStudionText} />
                    </div>
                    <div className={styles.addressBlock}>
                        <span className={styles.title}>Business Address:</span>
                        <br />
                        Calaton Studio{' '}
                        <span className={styles.addressWhiteSpace}>
                            19925 Stevens Creek BIvd Ste 184,
                        </span>
                        <br />
                        Cupertino, CA 95014, <span className={styles.noWrap}>United States</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Address
