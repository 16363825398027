import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'
import Modal from '../Modal'

import styles from './styles.module.scss'

const ContactModal = ({ formReset }) => {
    const modalContainerClass = classNames('container', styles.modal)

    const closeModal = () => {
        formReset()
    }

    return (
        <Modal onClose={closeModal}>
            <div className={modalContainerClass}>
                <div className={styles.close} onClick={closeModal}>
                    <div className={styles.strip}></div>
                    <div className={styles.strip}></div>
                </div>
                <div className={styles.logoContainer}></div>
                <div className={styles.content}>
                    <h3 className={styles.title}>
                        Thanks!
                        <br /> Your request has been sent
                    </h3>
                    <span>We will contact you soon</span>
                    <button id="continue" className={styles.continue} onClick={closeModal}>
                        Continue
                    </button>
                </div>
            </div>
        </Modal>
    )
}

ContactModal.propTypes = {
    formReset: PT.func,
}

export default ContactModal
