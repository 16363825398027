import React from 'react'
import PT from 'prop-types'

import SiteAppTypes from '../SiteAppTypes'
import appBrief from '../../docs/App-brief.pdf'

const ourServices = {
    stages: [
        {
            title: 'Logo Development',
            text:
                'Your visual identity starts with a logo. With a compelling creative and strategic lens, we help you craft your unique, memorable emblem that sparks brand recognition and awareness.',
            timeframe: 'from 14 days',
        },
        {
            title: 'Printed Products',
            text:
                'On-brand flyers, booklets, business cards, posters, calendars, packaging, magazines, and large-format products reach customers on a broader scale.',
            timeframe: 'from 14 days',
        },
        {
            title: 'Illustrations',
            text:
                'Our brains process visual information faster than they do written words. Captivate audiences and enhance your brand’s value proposition with custom illustrations.',
            timeframe: 'from 20 days',
        },
        {
            title: 'Motion graphics',
            text:
                'Put your ideas in motion. Our team of designers and animators will help you drive home key messages with dynamic text animation, graphics, and visual effects.',
            timeframe: 'from 30 days',
        },
        {
            title: 'Photo processing',
            text:
                'We’ll enhance your photo assets to ensure they’re on-brand and high-quality. Just let us know your vision for your photos and we’ll get to work!',
            timeframe: 'from 3 days',
        },
        {
            title: 'Customized solutions',
            text:
                'Don’t see what you’re looking for? Let’s chat. We’ll flex our creative muscles to craft customized solutions that speak to your brand’s ingenuity.',
            timeframe: 'from 60 days',
        },
    ],

    brief: {
        name: 'App-brief',
        file: appBrief,
    },
}

const OurServicesModal = ({ closeModal }) => (
    <SiteAppTypes data={ourServices} closeModal={closeModal} />
)

OurServicesModal.propTypes = {
    closeModal: PT.func,
}

export default OurServicesModal
