const GOOGLE_PUBLIC = process.env.GATSBY_GOOGLE_PUBLIC

export default function formSubmit(formData) {
    return new Promise((resolve, reject) => {
        let preloader = document.getElementById('preloader')
        preloader.classList.add('show')
        grecaptcha.ready(function () {
            grecaptcha.execute(GOOGLE_PUBLIC, { action: 'homepage' }).then(
                function (token) {
                    formData.append('token', token)

                    let data = Array.from(formData.entries()).reduce(
                        (memo, pair) => ({
                            ...memo,
                            [pair[0]]: pair[1],
                        }),
                        {}
                    )
                    fetch('/.netlify/functions/formsubmit', {
                        method: 'POST',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                        },
                        body: JSON.stringify(data),
                    })
                        .finally(() => {
                            preloader.classList.remove('show')
                        })
                        .then(res => {
                            if (res.status >= 200 && res.status < 300) {
                                return res
                            } else {
                                let error = new Error(res.statusText)
                                error.response = res
                                throw error
                            }
                        })
                        .then(res => {
                            return res.json()
                        })
                        .then(response => {
                            if (response.error) {
                                if (response.error === 'INVALID_EMAIL') {
                                    alert(' Please, enter a valid email')
                                }
                                reject()
                            }
                            resolve(response)
                        })
                        .catch(e => {
                            alert('Error! Please, try again')
                            reject(e)
                        })
                },
                function (error) {
                    preloader.classList.remove('show')
                    alert('Error! Please, try again later')
                    console.log('Recaptcha validation error: ', error)
                }
            )
        })
    })
}
