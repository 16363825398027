import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import classNames from 'classnames'

import Grade from '../Grade'
import SvgSpriteIcon from '../SvgSprite/SvgSpriteIcon'
import ArrowIcon from '../SvgSprite/ArrowIcon'
import thomas from 'src/images/optimized/png/Thomas_Mann.png'
import alice from 'src/images/optimized/png/Alice_McAdams.png'
import john from 'src/images/optimized/png/John_Hilfiger.png'

import styles from './styles.module.scss'

SwiperCore.use([Pagination])

class Feedbacks extends Component {
    state = {
        prevDisable: true,
        nextDisable: false,
    }

    feedbacksSlider = React.createRef()

    setDisable = swiper => {
        this.setState({ prevDisable: swiper.isBeginning })
        this.setState({ nextDisable: swiper.isEnd })
    }

    switchToThePrevtSlide = () => {
        this.feedbacksSlider.current.swiper.slidePrev()
    }

    switchToTheNextSlide = () => {
        this.feedbacksSlider.current.swiper.slideNext()
    }

    render() {
        const feedbacks = [
            {
                name: 'Thomas Mann',
                feedback:
                    'An extraordinary approach and professionalism is what distinguishes this studio from all others!',
                grade: 5,
                photo: thomas,
            },
            {
                name: 'Alice McAdams',
                feedback:
                    'I was really impressed with how guys were able to take my vision and turn it into reality, very quickly.',
                grade: 5,
                photo: alice,
            },
            {
                name: 'John Hilfiger',
                feedback:
                    "The design and development quality are superb, and they're also interesting, good people to work with.",
                grade: 5,
                photo: john,
            },
        ]

        const parameters = {
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletActiveClass: styles.active,
                bulletClass: styles.bullet,
            },
            breakpoints: {
                280: {
                    spaceBetween: 8,
                    slidesOffsetAfter: 5,
                },
                330: {
                    spaceBetween: 15,
                    slidesOffsetAfter: 30,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    slidesOffsetAfter: 0,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    slidesOffsetAfter: 0,
                },
            },
        }

        const arrowSecondary = {
            usual: 'arrowSecondary',
            hover: 'arrowSecondaryHover',
            disabled: 'arrowSecondaryDisabled',
        }

        const titleContainerClass = classNames('container', styles.title)
        const nextBtnClass = classNames('next', styles.next, styles.arrow)
        const prevBtnClass = classNames('prev', styles.prev, styles.arrow)
        const pagination = classNames(
            'swiper-pagination swiper-pagination-bullets ',
            styles.pagination
        )

        return (
            <section className={styles.feedback}>
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <h3 className={titleContainerClass}>
                            Here’s What Clients <br /> Have To Say
                        </h3>
                        <Swiper
                            ref={this.feedbacksSlider}
                            {...parameters}
                            className={styles.slider}
                            onProgress={this.setDisable}
                        >
                            {feedbacks.map(({ name, feedback, grade, photo }) => (
                                <SwiperSlide className={styles.slide} key={name}>
                                    <div className={styles.content}>
                                        <SvgSpriteIcon
                                            name="quotes"
                                            className={styles.smallQuotes}
                                        />
                                        <p className={styles.text}>{feedback}</p>
                                        <div className={styles.dataWrapper}>
                                            <img src={photo} alt={name} className={styles.photo} />
                                            <div>
                                                <span className={styles.name}>{name}</span>
                                                <span>
                                                    <Grade quantity={grade} />
                                                </span>
                                            </div>
                                            <SvgSpriteIcon
                                                name="quotes"
                                                className={styles.quotes}
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                            <div className={styles.navigation}>
                                <ArrowIcon
                                    className={prevBtnClass}
                                    disabled={this.state.prevDisable}
                                    arrow={arrowSecondary}
                                    onClick={this.switchToThePrevtSlide}
                                />
                                <ArrowIcon
                                    className={nextBtnClass}
                                    disabled={this.state.nextDisable}
                                    arrow={arrowSecondary}
                                    onClick={this.switchToTheNextSlide}
                                />
                            </div>
                            <div className={pagination}></div>
                        </Swiper>
                    </div>
                </div>
            </section>
        )
    }
}

export default Feedbacks
