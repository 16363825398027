import React, { useState } from 'react'
import classNames from 'classnames'

import Steps from 'src/components/Steps'
import RoadMapModal from '../RoadMapModal'

import styles from './styles.module.scss'

const classNamesHeaderSteps = classNames(styles.headerSteps, 'container')

const Roadmap = () => {
    const [modalOpen, setOpenModal] = useState(false)

    const closeModal = () => {
        setOpenModal(false)
    }

    return (
        <section className={styles.roadmap}>
            <div className={styles.way}>
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <h3 className={styles.title}>Getting Started Is Easy</h3>
                        <p className={styles.text}>
                            We act as an extension of your team and listen intently, ensuring we
                            know exactly what you need and how we will deliver it.{' '}
                            <span className={styles.textDesktop}>
                                Got a project in mind? Here’s how it works:
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={classNamesHeaderSteps}>
                <h3>Got a project in mind? Here’s how it works: </h3>
            </div>
            <Steps />
            <div className="container">
                <span className={styles.line} />
                <button className={styles.btn} type="button" onClick={() => setOpenModal(true)}>
                    Project roadmap
                </button>
            </div>
            {modalOpen && <RoadMapModal closeModal={closeModal} />}
        </section>
    )
}

export default Roadmap
