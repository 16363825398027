import linguamillMobile from 'src/images/optimized/jpg/cases/linguamill/mobile/linguamillMobile.jpg'
import linguamillMobileRetina from 'src/images/optimized/jpg/cases/linguamill/mobile/linguamillMobile@2x.jpg'
import linguamillDesktop from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktop.jpg'
import linguamillDesktopRetina from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktop@2x.jpg'
import linguamillDesktopBig from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktopBig.jpg'
import linguamillDesktopBigRetina from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktopBig@2x.jpg'
import calatonStudioMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioMobile.jpg'
import calatonStudioMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioMobile@2x.jpg'
import calatonStudioDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioDesktop.jpg'
import calatonStudioDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioDesktop@2x.jpg'
import calatonStudioBrandingMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioBrandingMobile.jpg'
import calatonStudioBrandingMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioBrandingMobile@2x.jpg'
import calatonStudioBrandingDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioBrandingDesktop.jpg'
import calatonStudioBrandingDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioBrandingDesktop@2x.jpg'
import calatonSystemsMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsMobile.jpg'
import calatonSystemsMobileRetina from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsMobile@2x.jpg'
import calatonSystemsDesktop from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsDesktop.jpg'
import calatonSystemsDesktopRetina from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsDesktop@2x.jpg'
import calatonSystemsBrandingMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsBrandingMobile.jpg'
import calatonSystemsBrandingMobileRetina from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsBrandingMobile@2x.jpg'
import calatonSystemsBrandingDesktop from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsBrandingDesktop@2x.jpg'
import calatonSystemsBrandingDesktopRetina from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsBrandingDesktop@2x.jpg'

export const caseLinguamillApp = {
    title: 'Linguamill <span style="white-space: nowrap"> Mobile Design </span>',
    pictureBig: {
        source: [
            {
                srcSet: linguamillMobile,
                retina: linguamillMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: linguamillDesktopBig,
                retina: linguamillDesktopBigRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: linguamillDesktopBig,
        retina: linguamillDesktopBigRetina,
        alt: 'case Linguamill',
    },
    picture: {
        source: [
            {
                srcSet: linguamillMobile,
                retina: linguamillMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: linguamillDesktop,
                retina: linguamillDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: linguamillDesktop,
        retina: linguamillDesktopRetina,
        alt: 'case Linguamill Mobile Design',
    },
    description:
        'Linguamill is an app for learn languages effortlessly. The app integrates with online services and retail platforms such as Amazon Vendor, Kobo, Google, and others.',
    link: '/cases/linguamill/',
}

export const caseCalatonStudioWeb = {
    title: 'Calaton Studio <span style="white-space: nowrap"> Web Design </span>',
    picture: {
        source: [
            {
                srcSet: calatonStudioMobile,
                retina: calatonStudioMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: calatonStudioDesktop,
                retina: calatonStudioDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: calatonStudioDesktop,
        retina: calatonStudioDesktopRetina,
        alt: 'case Calaton Studio Web & UI/UX Design',
    },
    description:
        'Visual communication for the design studio, which is part of the Calaton systems company.',
    link: '/cases/calaton-studio-web',
}

export const caseCalatonStudioBranding = {
    title: 'Calaton Studio <span style="white-space: nowrap"> Branding </span>',
    picture: {
        source: [
            {
                srcSet: calatonStudioBrandingMobile,
                retina: calatonStudioBrandingMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: calatonStudioBrandingDesktop,
                retina: calatonStudioBrandingDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: calatonStudioBrandingDesktop,
        retina: calatonStudioBrandingDesktopRetina,
        alt: 'case Calaton Studio Brand Identity',
    },
    description:
        'Brand identity for Calaton studio company, which is part of the Calaton systems company.',
    link: '/cases/calaton-studio-branding-web',
}

export const caseCalatonSystemsWeb = {
    title: 'Calaton Systems <span style="white-space: nowrap"> Web Design </span>',
    picture: {
        source: [
            {
                srcSet: calatonSystemsMobile,
                retina: calatonSystemsMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: calatonSystemsDesktop,
                retina: calatonSystemsDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: calatonSystemsDesktop,
        retina: calatonSystemsDesktopRetina,
        alt: 'case Calaton Systems Web & UI/UX Design',
    },
    description:
        'Visual communication for the Developer company, which is part of the Calaton systems company.',
    link: '/cases/calaton-systems-web',
}

export const caseCalatonSystemsBranding = {
    title: 'Calaton Systems <span style="white-space: nowrap"> Branding </span>',
    picture: {
        source: [
            {
                srcSet: calatonSystemsBrandingMobile,
                retina: calatonSystemsBrandingMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: calatonSystemsBrandingDesktop,
                retina: calatonSystemsBrandingDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: calatonSystemsBrandingDesktop,
        retina: calatonSystemsBrandingDesktopRetina,
        alt: 'case Calaton Systems Brand Identity',
    },
    description:
        'Brand identity for Calaton studio company, which is part of the Calaton systems company.',
    link: '/cases/calaton-systems-branding-web',
}
