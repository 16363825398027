import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Modal from '../Modal'
import LetsTalk from '../LetsTalk'
import brandingBrief from '../../docs/Branding-brief.pdf'

import styles from './styles.module.scss'

const brief = {
    name: 'Branding-brief',
    file: brandingBrief,
}

const packageTypes = [
    {
        packageName: 'Basic',
        options: [
            'Two original, custom-drawn logo options',
            'Logo usage guidelines',
            'Branded print and digital collateral (business cards, envelopes, and email templates)',
        ],
        description:
            'Our Basic Package is the best bang for your buck, delivering high-quality branding materials for on-the-rise companies ready to scale.',
    },
    {
        packageName: 'Optimal',
        options: [
            'Three original, custom-drawn logo options',
            'Guideline with identity usage rules Logo usage guidelines',
            'Branded print and digital collateral (business cards, envelopes, email templates, presentation templates, and social media templates)',
        ],
        description:
            'Our Optimal Package is best suited for established companies ready to take their brand to the next level.',
    },
    {
        packageName: 'Premium',
        options: [
            'Five original, custom-drawn logo options',
            'Comprehensive brand guidelines and identity usage rules, including for tone of voice, visual elements, and marketing materials',
            'Branded print and online identity materials (office stationery, presentation and social media templates, navigation systems, and outdoor systems)',
        ],
        description:
            'For those looking to go above and beyond, Premium elevates your brand identity to unprecedented heights.',
    },
]

const PackageTypesModal = ({ closeModal }) => {
    const modalContainerClass = classNames('container', styles.modal)
    const classNamesBtnLeaveRequest = classNames(styles.btnLeaveRequest, 'btn')
    const classNamesBtnContainer = classNames(styles.btnContainer, styles.line)

    return (
        <Modal onClose={closeModal}>
            <div className={modalContainerClass}>
                <div className={styles.contentContainer}>
                    <header className={styles.modalHeader}>
                        <div className={styles.close} onClick={closeModal}>
                            <div className={styles.strip}></div>
                            <div className={styles.strip}></div>
                        </div>
                    </header>
                    <div className={styles.content}>
                        <div className={styles.cards}>
                            {packageTypes.map(({ packageName, options, description }) => (
                                <div className={styles.card} key={packageName}>
                                    <h4 className={styles.title}>{packageName}</h4>
                                    <ul className={styles.List}>
                                        {options.map((optionsItem, i) => (
                                            <li className={styles.listItem} key={i}>
                                                {optionsItem}
                                            </li>
                                        ))}
                                    </ul>
                                    <p className={styles.description}>{description}</p>
                                    <div className={classNamesBtnContainer} onClick={closeModal}>
                                        <AnchorLink
                                            to="/#contact"
                                            title="Leave request"
                                            className={classNamesBtnLeaveRequest}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.letsTalkContainer}>
                            <LetsTalk brief={brief} onClick={closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

PackageTypesModal.propTypes = {
    closeModal: PT.func,
}

export default PackageTypesModal
