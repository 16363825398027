import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'

import styles from './styles.module.scss'

const ColorChar = ({ children }) => {
    const text = children.replace(/(i)/g, `<span class=${styles.colored}>i</span>`)
    return <>{reactHtmlParser(text)}</>
}

ColorChar.propTypes = {
    children: PropTypes.string,
}

export default ColorChar
