import React, { Component } from 'react'
import PT from 'prop-types'
import { withFormik } from 'formik'
import classNames from 'classnames'
import { Link } from 'gatsby'

import ContactModal from '../ContactModal'
import SvgSpriteIcon from '../SvgSprite/SvgSpriteIcon'
import formsubmit from '../formsubmit/formsubmit'
import validator from './validator'

import styles from './styles.module.scss'

const formikWrapper = withFormik({
    mapPropsToValues: () => ({
        fullName: '',
        company: '',
        email: '',
        message: '',
        privacy: false,
    }),
    handleSubmit: (values, { setSubmitting, setErrors }) => {
        setSubmitting(false)
        const validate = validator.validateFull(values)

        if (JSON.stringify(validate) === '{}') {
            setTimeout(() => {
                let fd = Object.entries(values).reduce(
                    (fd, [k, v]) => (fd.append(k, v), fd),
                    new FormData()
                )
                const fullName = values.fullName.trim()
                const [firstName, lastName] = fullName.split(' ')
                fd.append('firstname', firstName)
                fd.append('lastname', lastName ? lastName : '-')
                formsubmit(fd).then(() => {
                    setSubmitting(true)
                })
            }, 1000)
        } else {
            setErrors(validate)
        }
    },
})

class Contact extends Component {
    fieldClassName = (field, className) => {
        return classNames(className, {
            [styles.error]: this.props.errors[field] && this.props.touched[field],
        })
    }

    isBtnDisabled = () => {
        const isFieldEmpy = validator.validationFieldsForEmpty(this.props.values).AllFields
        if (
            isFieldEmpy ||
            (Object.keys(this.props.errors).length && Object.keys(this.props.touched).length)
        )
            return true
        else return false
    }

    renderInputs() {
        const { values } = this.props
        const inputs = [
            {
                name: 'fullName',
                type: 'text',
                placeholder: 'Full Name',
            },
            {
                name: 'email',
                type: 'email',
                placeholder: 'Email',
            },
            {
                name: 'company',
                type: 'text',
                placeholder: 'Company',
            },
        ]
        return inputs.map(({ name, type, placeholder }) => (
            <input
                id={name}
                name={name}
                value={values[name]}
                type={type}
                placeholder={placeholder}
                onChange={e => this.onChange(e)}
                key={placeholder}
                className={this.fieldClassName(name)}
            />
        ))
    }

    componentDidMount() {
        const { setValues, values } = this.props
        const fieldsNames = Object.keys(values)
        const fieldsValues = {}

        fieldsNames.map(field => {
            field === 'privacy'
                ? (fieldsValues[field] = sessionStorage.getItem(field) === 'true')
                : (fieldsValues[field] = sessionStorage.getItem(field) || '')
        })

        setValues(fieldsValues)
    }

    onChange(e) {
        const { handleChange } = this.props
        const input = e.target
        const name = input.getAttribute('name')
        const value = input.type === 'checkbox' ? input.checked : input.value

        handleChange(e)
        sessionStorage.setItem(name, value)
    }

    resetForm() {
        const { handleReset, values } = this.props
        const fields = Object.keys(values)

        handleReset()
        fields.map(field => {
            sessionStorage.removeItem(field)
        })
    }

    render() {
        const containerSectionClass = classNames('container', styles.contact)
        const { values, handleSubmit, isSubmitting } = this.props

        return (
            <>
                <section className={containerSectionClass} id="contact">
                    <h2 className={styles.title}>Contact us</h2>
                    <form className={styles.form}>
                        <div className={styles.inputsWrapper}>
                            <div className={styles.formArea}>{this.renderInputs()}</div>
                            <div className={styles.formArea}>
                                <label
                                    htmlFor="message"
                                    className={this.fieldClassName(
                                        'message',
                                        styles.descriptionLabel
                                    )}
                                >
                                    Project description
                                </label>
                                <textarea
                                    name="message"
                                    value={values.message}
                                    rows="10"
                                    onChange={e => {
                                        this.onChange(e)
                                    }}
                                    className={this.fieldClassName('message')}
                                />
                                <div className={styles.checkboxWrapper}>
                                    <input
                                        name="privacy"
                                        id="privacy"
                                        type="checkbox"
                                        value={values.privacy}
                                        checked={values.privacy}
                                        className={styles.privacy}
                                        onChange={e => this.onChange(e)}
                                    />
                                    <label htmlFor="privacy" className={styles.privacyLabel}>
                                        I consent to having Calaton Studio collect and process my
                                        personal details according to its
                                        <Link to="/privacy">Privacy&nbsp;Policy.</Link>
                                        <SvgSpriteIcon name="check" className={styles.iconCheck} />
                                    </label>
                                    <button
                                        type="submit"
                                        id="contactBtn"
                                        className={styles.contactBtn}
                                        disabled={this.isBtnDisabled()}
                                        onClick={handleSubmit}
                                    >
                                        Contact us
                                    </button>
                                </div>
                                <div className={styles.brandingReCaptcha}>
                                    This site is protected by reCAPTCHA and the Google
                                    <a href="https://policies.google.com/privacy">
                                        &#160;Privacy Policy
                                    </a>{' '}
                                    and
                                    <a href="https://policies.google.com/terms">
                                        &#160;Terms of Service
                                    </a>{' '}
                                    apply.
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                {isSubmitting && <ContactModal formReset={this.resetForm.bind(this)} />}
            </>
        )
    }
}

Contact.propTypes = {
    errors: PT.object,
    touched: PT.object,
    setValues: PT.func,
    values: PT.shape({
        message: PT.string,
        privacy: PT.bool,
    }),
    isSubmitting: PT.bool,
    handleChange: PT.func,
    handleBlur: PT.func,
    handleSubmit: PT.func,
    handleReset: PT.func,
}

export default formikWrapper(Contact)
