import React from 'react'
import PT from 'prop-types'

import SiteAppTypes from '../SiteAppTypes'
import appBrief from '../../docs/App-brief.pdf'

const appTypes = {
    stages: [
        {
            title: 'Promo Apps',
            text:
                'Make it easy for your customers to connect with your brand. Promo apps keep your brand top of mind while allowing customers to order goods and services, leave reviews, receive bonuses, and discover discounts.',
            timeframe: 'from 14 days',
        },
        {
            title: 'Content Apps',
            text:
                'Give your users easy access to useful, life-enriching content — directly from their iPhone or Android. Create stunning apps that provide users with things like news, exchange rates, exercise programs, or language courses. Generate revenue either through ads or pay-per-download.',
            timeframe: 'from 20 days',
        },
        {
            title: 'Productivity Apps',
            text:
                'Inspire your audience to live to their fullest potential with a customized, on-brand productivity app. We’ll help you generate a solution that engages users and keeps them coming back for more.',
            timeframe: 'from 30 days',
        },
        {
            title: 'Social Media Apps',
            text:
                'Redefine community. Revolutionize connection. We’ll help you create small-scale social networking apps that bring people together with the click of a button.',
            timeframe: 'from 30 days',
        },
    ],

    brief: {
        name: 'App-brief',
        file: appBrief,
    },
}

const AppTypesModal = ({ closeModal }) => <SiteAppTypes data={appTypes} closeModal={closeModal} />

AppTypesModal.propTypes = {
    closeModal: PT.func,
}

export default AppTypesModal
