import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'
import ColorChar from 'src/components/ColorChar'

import styles from './styles.module.scss'

const content = [
    {
        icon: 'layoutIcon',
        text: 'Crisp, clean layouts for stunning print and digital collateral.',
    },
    {
        icon: 'copywritingIcon',
        text: 'Excellent, compelling copywriting that’s true to your brand tone of voice.',
    },
    {
        icon: 'fastSpeedIcon',
        text: 'Quick page loading — even if you have complex visual effects.',
    },
    {
        icon: 'appDesignIcon',
        text:
            'Responsive, vibrant mobile app design to add creativity and flair to your business offerings.',
    },
    {
        icon: 'UXIcon',
        text: 'An exceptional user experience that captures audiences and converts.',
    },
    {
        icon: 'animationIcon',
        text: 'Informative and brilliant animation that works cohesively with your site.',
    },
]

const casesWrapClass = classNames(styles.wrapper, 'container')

const QualityAndDetails = () => (
    <section className={styles.section}>
        <div className={casesWrapClass}>
            <h2 className={styles.title}>
                <ColorChar>Quality Design</ColorChar>{' '}
                <span className={styles.noWrap}>
                    <ColorChar>Is In The Details</ColorChar>
                </span>
            </h2>
            <p className={styles.subTitle}>
                Calaton Studio is committed to driving impact with best-in-class design. <br />{' '}
                Don’t just take it from us — check out the work <br /> we’ve done for our amazing
                clients!
            </p>
            <div className={styles.items}>
                {content.map(({ icon, text }) => (
                    <div key={text} className={styles.item}>
                        <SvgSprite name="circleIcon" className={styles.circleIcon} />
                        <SvgSprite name={icon} className={styles.svg} />
                        <span className={styles.text}>{text}</span>
                    </div>
                ))}
                <SvgSprite name="circleIcon" className={styles.circleIconLast} />
            </div>
        </div>
    </section>
)

export default QualityAndDetails
