import React from 'react'
import PT from 'prop-types'
import SvgSpriteIcon from '../SvgSprite/SvgSpriteIcon'

const Grade = ({ quantity }) => {
    return [...Array(quantity)].map((el, i) => <SvgSpriteIcon name="star" key={i} />)
}

Grade.propTypes = {
    quantity: PT.number.isRequired,
}
export default Grade
