import React from 'react'
import PT from 'prop-types'

import SiteAppTypes from '../SiteAppTypes'
import webBrief from '../../docs/Web-brief.pdf'

const siteTypes = {
    stages: [
        {
            title: 'Business card website',
            text:
                'Essentially a virtual business card, this website is a snapshot of your company’s most valuable information. This typically includes your contact information, prices, descriptions of your services or products, and your location.',
            timeframe: 'from 14 days',
        },
        {
            title: 'Landing Page',
            text:
                'A landing page is more than just a homepage — it’s the official face of your company. Landing pages explain to your customers in clear, compelling language how your business will solve their problems. They also include any special offers and calls to action.',
            timeframe: 'from 20 days',
        },
        {
            title: 'Corporate website',
            text:
                'A corporate website corresponds to your corporate identity. It should inspire confidence in potential customers, partners, and the press, which is why we pay special attention to design and usability in these projects.',
            timeframe: 'from 30 days',
        },
        {
            title: 'Web Application',
            text:
                'Connect with your customers quickly and conveniently through easy-to-use web apps. We make it easy for the users to find what they need, when they need it. Improve your company’s workflows with thoughtful UI design.',
            timeframe: 'from 30 days',
        },
        {
            title: 'Customized solutions',
            text:
                'Your business has unique needs, and we’re here to bring your vision to life. Building unique websites is as fun as it is capable for us. Let’s chat.',
            timeframe: 'from 60 days',
        },
    ],

    brief: {
        name: 'Web-brief',
        file: webBrief,
    },
}

const SiteTypesModal = ({ closeModal }) => <SiteAppTypes data={siteTypes} closeModal={closeModal} />

SiteTypesModal.propTypes = {
    closeModal: PT.func,
}

export default SiteTypesModal
