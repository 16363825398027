import React, { Component } from 'react'

import Header from '../Header'
import About from '../About'
import Roadmap from '../Roadmap'
import Services from '../Services'
import Cases from '../Cases'
import QualityAndDetails from '../QualityAndDetails'
import Address from '../Address'
import AnotherWebsite from '../AnotherWebsite'
import Feedbacks from '../Feedbacks'
import Contact from '../Contact'
import Anywhere from '../Anywhere'
import Footer from '../Footer'
import Preloader from '../Preloader'

class App extends Component {
    render() {
        return (
            <>
                <Header />
                <About />
                <Roadmap />
                <Services />
                <Cases />
                <AnotherWebsite />
                <Feedbacks />
                <QualityAndDetails />
                <Address />
                <Contact />
                <Anywhere />
                <Footer />
                <Preloader />
            </>
        )
    }
}

export default App
