import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'
import ArrowIcon from 'src/components/SvgSprite/ArrowIcon'
import ColorChar from 'src/components/ColorChar'
import brandingBrief from '../../docs/Branding-brief.pdf'
import webBrief from '../../docs/Web-brief.pdf'
import appBrief from '../../docs/App-brief.pdf'

import styles from './styles.module.scss'

const Anywhere = () => {
    const container = classNames(styles.wrapper, 'container')

    const content = [
        {
            text: 'Brand identity',
            icon: 'brandIdentityIcon',
            brief: {
                name: 'Branding-brief',
                file: brandingBrief,
            },
        },
        {
            text: 'Web Design',
            icon: 'webAndUXUIDesignIcon',
            brief: {
                name: 'Web-brief',
                file: webBrief,
            },
        },
        {
            text: 'Mobile Design',
            icon: 'mobileAndDesktopAppIcon',
            brief: {
                name: 'App-brief',
                file: appBrief,
            },
        },
    ]

    return (
        <section className={styles.anywhere}>
            <div className={container}>
                <div className={styles.header}>
                    <h2 className={styles.title}>
                        <ColorChar>We Design </ColorChar>
                        <span className={styles.noWrap}>For The World</span>
                    </h2>
                    <p className={styles.subtitle}>
                        Our headquarters are in California, but we collaborate with clients near and
                        far. No matter where you are in the world, get in touch. We look forward to
                        being your creative partners!
                    </p>
                </div>
                <div className={styles.onlineBrief}>
                    {content.map(({ text, icon, brief }, i) => (
                        <div className={styles.item} key={i}>
                            <SvgSprite name={icon} className={styles.icon} />
                            <div className={styles.text}>
                                Download brief <br />
                                <span className={styles.textSize}>{text}</span>
                            </div>
                            <a
                                className={styles.linkDownloadBrief}
                                href={brief.file}
                                download={brief.name}
                            >
                                <ArrowIcon className={styles.arrow} />
                            </a>
                            <SvgSprite name="circleIcon" className={styles.circle} />
                        </div>
                    ))}
                    <SvgSprite name="circleIcon" className={styles.circleLast} />
                </div>
            </div>
        </section>
    )
}

export default Anywhere
