import React from 'react'
import PT from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import classNames from 'classnames'

import styles from './styles.module.scss'

const ButtonsSlider = ({ slides, onClick, className, slideClassName }) => {
    const swiperClass = classNames(className, styles.slider)
    const swiperSlideClass = classNames(styles.button, slideClassName)
    const showActiveSlide = swiper => {
        swiper.activeIndex = swiper.clickedIndex ? swiper.clickedIndex : 0
    }

    const parameters = {
        onSlideChange: showActiveSlide,
        onClick: onClick,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        normalizeSlideIndex: false,
        spaceBetween: 10,
        slidesOffsetAfter: 30,
        freeMode: true,
        breakpoints: {
            700: {
                spaceBetween: 20,
            },
            900: {
                spaceBetween: 30,
                slidesOffsetAfter: 0,
            },
        },
    }

    return (
        <Swiper {...parameters} className={swiperClass}>
            {slides.map(slide => (
                <SwiperSlide key={slide.title} className={swiperSlideClass}>
                    {slide.title}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

ButtonsSlider.propTypes = {
    slides: PT.array.isRequired,
    onClick: PT.func,
    className: PT.string,
    slideClassName: PT.string,
}

export default ButtonsSlider
