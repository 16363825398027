export default {
    errors: {},
    getErrors() {
        return this.errors
    },
    validateFull(values) {
        this.errors = this.validationFieldsForEmpty(values)
        this.errors = this.validationPrivacyCheckbox(values)
        this.errors = this.validationFieldFullName(values)
        this.errors = this.validationFieldEmail(values)
        this.errors = this.validationFieldCompany(values)
        this.errors = this.validationFieldMessage(values)

        return this.errors
    },
    validationFieldFullName(values) {
        if (!/^([A-Za-z.]+\s?){1,4}\S$/u.test(values.fullName))
            this.errors.fullName = 'should match pattern "^([A-Za-z][A-Za-z.]+s?){1,4}S$"'
        else delete this.errors.fullName

        return this.getErrors()
    },
    validationFieldEmail(values) {
        if (
            !/^[a-z0-9_-]+\.*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,10}$/u.test(
                values.email
            )
        )
            this.errors.email = 'should match pattern "email"'
        else delete this.errors.email

        return this.getErrors()
    },
    validationFieldCompany(values) {
        if (!/^([A-Za-z0-9]\s?[A-Za-z0-9._-]+\s?){1,4}\S$/u.test(values.company))
            this.errors.company = 'should match pattern "^([A-Za-z0-9]s?[A-Za-z0-9._-]+s?){1,4}S$"'
        else delete this.errors.company

        return this.getErrors()
    },
    validationFieldMessage(values) {
        if (!/^([a-zA-Z0-9]+[^\sа-яА-Я]*\s?\n?)+$/u.test(values.message))
            this.errors.message = 'should match pattern "^([A-Za-z0-9]+s*)+$"'
        else delete this.errors.message

        return this.getErrors()
    },
    validationFieldsForEmpty(values) {
        const { fullName, company, email, message, privacy } = values

        if (!(fullName && email && company && message && privacy))
            this.errors.AllFields = 'all fields must be filled in'
        else delete this.errors.AllFields

        return this.getErrors()
    },
    validationPrivacyCheckbox(values) {
        if (!values.privacy) this.errors.privacy = 'should be equal to one of the allowed values'
        else delete this.errors.privacy

        return this.getErrors()
    },
}
