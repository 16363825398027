import React from 'react'
import { Component } from 'react'
import classNames from 'classnames'

import ColorChar from '../ColorChar'
import ServicesSlider from '../ServicesSlider'

import styles from './styles.module.scss'

class Services extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: true,
            effect: 'slide',
            noSwiping: '',
        }
    }

    componentDidMount() {
        this.onResize()
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    onResize = () => {
        const width = window.innerWidth

        if (width >= 990) {
            if (this.state.isMobile) {
                this.setState({
                    isMobile: false,
                    effect: 'fade',
                    noSwiping: ' swiper-no-swiping ',
                })
            }
        } else if (!this.state.isMobile) {
            this.setState({
                isMobile: true,
                effect: 'slide',
                noSwiping: '',
            })
        }
    }

    render() {
        const servicesContainerClass = classNames(styles.container, 'container')

        return (
            <section id="services" className={styles.services} key={this.state.effect}>
                <div className={servicesContainerClass}>
                    <h2 className={styles.title}>
                        <ColorChar>Our Services</ColorChar>
                    </h2>
                    <p className={styles.text}>
                        Regardless of which product you are going to launch on the market, do not
                        forget about the importance of appearance and thoughtful functionality. We
                        offer a system of fresh business solutions that can satisfy any scale.
                    </p>
                </div>

                <ServicesSlider effect={this.state.effect} noSwiping={this.state.noSwiping} />
            </section>
        )
    }
}

export default Services
