import React from 'react'
import classNames from 'classnames'
import SvgSpriteIcon from '../SvgSprite/SvgSpriteIcon.js'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import ColorChar from 'src/components/ColorChar'

import styles from './styles.module.scss'

const classNamesRequestBtn = classNames(styles.requestBtn, 'btn')

const AnotherWebsite = () => {
    const contentClass = classNames('container', styles.content)
    return (
        <section className={styles.website}>
            <div className={contentClass}>
                <h3 className={styles.title}>
                    We <ColorChar>Commit</ColorChar> To The Craft
                </h3>
                <p className={styles.text}>
                    That means we never work with pre-made design templates or overused clichés.
                    Each project is as unique as a fingerprint, amplifying your business’ value
                    proposition with custom, inimitable design.
                </p>
                <div className={styles.linkWrapper}>
                    <SvgSpriteIcon name="at" className={styles.icon} />
                    <span>drop us a line: hello@calaton.studio</span>
                </div>
                <AnchorLink to="/#contact" title="Leave request" className={classNamesRequestBtn} />
            </div>
        </section>
    )
}

export default AnotherWebsite
