import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'

import styles from './styles.module.scss'

const Steps = () => {
    const containerSteps = classNames(styles.steps, 'container')
    const slides = [
        {
            title: 'Apply On The Website',
            text: 'Tell us about your company, your project, and the goal you’re trying to achieve',
        },
        {
            title: 'We’ll Schedule An Intro Call',
            text:
                'We’ll take an hour to discover more about your project and how we can best serve you.',
        },
        {
            title: 'We Get To Work',
            text:
                'Our team of developers, designers, and brand strategists builds a project roadmap to hit the ground running.',
        },
    ]

    return (
        <div className={containerSteps}>
            {slides.map(({ title, text }) => (
                <div className={styles.step} key={title}>
                    <h4 className={styles.title}>
                        {title}
                        <SvgSprite name="circleIcon" className={styles.circleIcon} />
                    </h4>
                    <span className={styles.text}>{text}</span>
                </div>
            ))}
            <SvgSprite name="circleIcon" className={styles.circleIconLast} />
        </div>
    )
}

export default Steps
