import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'
import Modal from '../Modal'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import SvgSprite from 'src/components/SvgSprite'
import LetsTalk from '../LetsTalk'

import styles from './styles.module.scss'

const SiteAppTypes = ({ data, closeModal }) => {
    const modalContainerClass = classNames('container', styles.modal)
    const classNamesBtnLeaveRequest = classNames(styles.btnLeaveRequest, 'btn')
    const classNamesTitle = classNames(styles.title, styles.line)
    const classNamesText = classNames(styles.text, styles.line)
    const classNamesTimeFrame = classNames(styles.timeframe, styles.line)
    const classNamesBtnContainer = classNames(styles.btnContainer, styles.line)

    return (
        <Modal onClose={closeModal}>
            <div className={modalContainerClass}>
                <div className={styles.contentContainer}>
                    <header className={styles.modalHeader}>
                        <div className={styles.close} onClick={closeModal}>
                            <div className={styles.strip}></div>
                            <div className={styles.strip}></div>
                        </div>
                    </header>
                    <div className={styles.content}>
                        <div className={styles.stagesContainer}>
                            {data.stages.map(({ title, text, timeframe }) => (
                                <div className={styles.stages} key={title}>
                                    <h4 className={classNamesTitle}>
                                        {title}
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    </h4>
                                    <p className={classNamesText}>
                                        {text}
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    </p>
                                    <p className={classNamesTimeFrame}>
                                        {timeframe}
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    </p>
                                    <div className={classNamesBtnContainer} onClick={closeModal}>
                                        <AnchorLink
                                            to="/#contact"
                                            className={classNamesBtnLeaveRequest}
                                            title="Leave request"
                                        />
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.letsTalkContainer}>
                            <LetsTalk brief={data.brief} onClick={closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

SiteAppTypes.propTypes = {
    data: PT.object,
    closeModal: PT.func,
}

export default SiteAppTypes
