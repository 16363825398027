import React from 'react'

import App from 'src/components/App/App.js'
import BaseContainer from 'src/components/BaseContainer'
import Meta from 'src/components/Meta'
import ogLogoCompany from 'src/images/optimized/jpg/cases/og-logo_company.jpg'

const metaData = {
    title: 'Calaton Studio - web design, mobile design, branding',
    description:
        'We are a team of experienced designers, developers, brand and marketing strategists who create fresh design solutions for businesses and startups',
    url: 'https://www.calaton.studio',
    img: `https://www.calaton.studio${ogLogoCompany}`,
    keywords: 'design, web design, mobile design, ui/ux design, branding',
}

const IndexPage = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <App />
        </BaseContainer>
    </>
)

export default IndexPage
