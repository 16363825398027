import React from 'react'

import styles from './styles.module.scss'

const Preloader = () => {
    return (
        <div id="preloader" className={styles.preloader}>
            <div className={styles.preloaderContainer}>
                <svg
                    width="100"
                    height="100"
                    viewBox="0 0 165 165"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="165" height="165" fill="transparent" />
                    <rect width="165" height="165" fill="transparent" />
                    <path
                        className={styles.shape1}
                        d="M150.302 53.5717C144.752 41.4641 136.174 31.0381 125.577 23.4708C121.204 20.2757 114.981 21.2847 111.785 25.8251C108.59 30.1973 109.599 36.4192 114.14 39.6143C122.045 45.1636 128.437 52.899 132.473 61.8116C132.642 62.3161 132.978 62.8206 133.146 63.325H132.978C133.314 64.334 133.651 65.5111 133.651 66.6883C133.651 72.0694 129.278 76.6098 123.727 76.6098C119.354 76.6098 115.654 73.751 114.308 69.8833C108.59 57.6076 96.1434 49.0313 81.6786 49.0313C61.9998 49.0313 46.0213 65.0066 46.0213 84.6815C46.0213 104.356 61.9998 120.332 81.6786 120.332C95.9752 120.332 108.422 111.755 113.972 99.6479C115.149 95.612 119.018 92.7533 123.391 92.7533C128.773 92.7533 133.314 97.1255 133.314 102.675C133.314 103.852 133.146 104.861 132.81 105.87H132.978C129.11 115.119 122.886 123.527 114.308 129.581C109.935 132.776 108.926 138.998 112.122 143.37C113.972 146.06 117.168 147.406 120.195 147.406C122.214 147.406 124.232 146.733 125.914 145.556C154.675 124.704 165.103 86.0268 150.302 53.5717Z"
                        fill="#C1E457"
                    />
                    <path
                        className={styles.shape2}
                        d="M42.6573 40.9596C39.6298 40.9596 36.7705 39.6143 34.7521 37.0919C31.3882 32.7197 32.2292 26.4977 36.6023 23.3027C49.8897 13.213 65.5318 8 82.0149 8C86.0516 8 90.0882 8.33632 94.1249 9.00897C99.5071 9.84977 103.207 14.8946 102.366 20.2758C101.525 25.6569 96.4796 29.3565 91.0974 28.5157C88.2381 28.0112 85.2106 27.843 82.1831 27.843C70.0731 27.843 58.4676 31.7107 48.8805 39.1098C46.8622 40.287 44.8438 40.9596 42.6573 40.9596Z"
                    />
                    <path
                        className={styles.shape3}
                        d="M22.9785 120.332C19.2782 120.332 15.7461 118.146 14.0642 114.614C9.35473 104.693 7 94.0986 7 82.9999C7 72.0694 9.35473 61.4753 13.896 51.5538C16.2507 46.5089 22.1375 44.491 27.0152 46.6771C31.8928 49.0313 34.0794 54.917 31.8928 59.7937C28.5289 67.0246 26.847 74.9282 26.847 82.9999C26.847 91.0717 28.5289 98.9752 31.8928 106.206C34.2476 111.083 32.061 116.968 27.0152 119.323C25.8378 119.995 24.3241 120.332 22.9785 120.332Z"
                    />
                    <path
                        className={styles.shape4}
                        d="M82.0149 158C65.5318 158 49.7215 152.787 36.6023 142.697C32.2292 139.334 31.3882 133.112 34.7521 128.908C38.116 124.536 44.3392 123.695 48.5441 127.058C58.1312 134.457 69.7367 138.325 81.8467 138.325C84.8742 138.325 87.9017 138.157 90.761 137.652C96.1432 136.812 101.189 140.511 102.198 145.892C103.039 151.274 99.339 156.318 93.9567 157.327C90.0882 157.664 86.0516 158 82.0149 158Z"
                    />
                </svg>
            </div>
        </div>
    )
}

export default Preloader
