import React, { useRef, useEffect } from 'react'
import PT from 'prop-types'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Modal from '../Modal'
import SvgSprite from 'src/components/SvgSprite'

import styles from './styles.module.scss'

const roadmap = {
    stages: [
        {
            stage: 'Interview',
            days: '2-3 day',
        },
        {
            stage: 'Technical Task',
            days: '5-7 day',
        },
        {
            stage: 'Mock-up',
            days: '10-16 day',
        },
        {
            stage: 'Design',
            days: '14-22 day',
        },
        {
            stage: 'Layout',
            days: '16-34 day',
        },
    ],
    posts: [
        {
            post: 'Manager',
            responsibilities: ['Interview', 'Technical Task', 'Mock-up', 'Design', 'Layout'],
        },
        {
            post: 'Marketing Specialist',
            responsibilities: ['Interview', 'Technical Task'],
        },
        {
            post: 'Designer',
            responsibilities: ['Mock-up', 'Design', 'Layout'],
        },
        {
            post: 'Layout Designer',
            responsibilities: ['Layout'],
        },
        {
            post: 'QA/QC',
            responsibilities: ['Layout'],
        },
    ],
}

SwiperCore.use([Pagination])

const parameters = {
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
        699: {
            spaceBetween: 20,
            slidesPerView: 2,
            centeredSlides: true,
        },
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        bulletActiveClass: styles.active,
        bulletClass: styles.bullet,
        watchOverflow: true,
    },
}

const modalContainerClass = classNames('container', styles.roadMapModal)
const classNamesStartProjectBtn = classNames(styles.btnStartproject, 'btn')
const pagination = classNames(styles.pagination, 'swiper-pagination swiper-pagination-bullets ')

const RoadMapModal = ({ closeModal }) => {
    const swiper = useRef(null)

    useEffect(() => {
        swiper.current && swiper.current.swiper.update()
    }, [])

    const getRoadMapMobile = () => {
        const getResponsibillityItems = (responsibilities, stage, days) => {
            const getClassNamesResponsibility = responsibility =>
                classNames(styles.roadDays, { [styles.responsibility]: responsibility })

            return (
                <div className={getClassNamesResponsibility(responsibilities.includes(stage))}>
                    {days}
                    {responsibilities.includes(stage) && (
                        <SvgSprite name="circleIcon" className={styles.circleIcon} />
                    )}
                    {responsibilities.includes(stage) && (
                        <SvgSprite name="circleIcon" className={styles.circleIconLast} />
                    )}
                </div>
            )
        }

        return (
            <Swiper className={styles.swiper} {...parameters} ref={swiper}>
                {roadmap.posts.map(({ post, responsibilities }) => (
                    <SwiperSlide className={styles.slide} key={post}>
                        <div className={styles.card}>
                            <h4 className={styles.cardTitle}>{post}</h4>
                            {roadmap.stages.map(({ stage, days }, i) => (
                                <div className={styles.timeLine} key={stage}>
                                    <div className={styles.road}>
                                        <div className={styles.roadPart}>
                                            {`0${i + 1}`}
                                            <br />
                                            {stage}
                                        </div>
                                        {getResponsibillityItems(responsibilities, stage, days)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </SwiperSlide>
                ))}
                <div className={pagination}></div>
            </Swiper>
        )
    }

    const getRoadMapDesktop = () => {
        const getClassNamesResponsibility = responsibility =>
            classNames(styles.line, { [styles.responsibility]: responsibility })
        return (
            <div className={styles.roadMapDesktop}>
                <div className={styles.post}>
                    {roadmap.posts.map(({ post }) => (
                        <h4 key={post}>{post}</h4>
                    ))}
                </div>
                <div className={styles.roadDesktop}>
                    <div className={styles.roadPartLine}>
                        {roadmap.stages.map(({ stage }, i) => (
                            <div className={styles.roadPart} key={stage}>
                                {`0${i + 1}`}
                                <br />
                                {stage}
                            </div>
                        ))}
                    </div>
                    {roadmap.posts.map(({ post, responsibilities }) => (
                        <div className={styles.timeLineDesktop} key={post}>
                            {roadmap.stages.map(({ stage }) => (
                                <div
                                    className={getClassNamesResponsibility(
                                        responsibilities.includes(stage)
                                    )}
                                    key={stage}
                                >
                                    {responsibilities.includes(stage) && (
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIcon}
                                        />
                                    )}
                                    {responsibilities.includes(stage) && (
                                        <SvgSprite
                                            name="circleIcon"
                                            className={styles.circleIconLast}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className={styles.timeLineDesktop}>
                        {roadmap.stages.map(({ days }) => (
                            <div className={styles.daysDesktop} key={days}>
                                {days}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal onClose={closeModal}>
            <div className={modalContainerClass}>
                <div className={styles.contentContainer}>
                    <div className={styles.close}>
                        <div onClick={closeModal}>
                            <div className={styles.strip}></div>
                            <div className={styles.strip}></div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.swiperContainer}>
                            {getRoadMapMobile()}
                            {getRoadMapDesktop()}
                        </div>
                    </div>
                    <div className={styles.btnContainer} onClick={closeModal}>
                        <AnchorLink to="/#contact" className={classNamesStartProjectBtn}>
                            Start project
                        </AnchorLink>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

RoadMapModal.propTypes = {
    closeModal: PT.func.isRequired,
}

export default RoadMapModal
