import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PT from 'prop-types'

import styles from './styles.module.scss'

const modalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null

class Modal extends Component {
    constructor() {
        super()
        this.el = typeof document !== `undefined` ? document.createElement('div') : null
        this.body = document.querySelector('body')
        this.elOverlay = React.createRef()
    }
    componentDidMount() {
        this.body.classList.add('overflowHidden')
        modalRoot.appendChild(this.el)
        window.addEventListener('keydown', this.handleKeyDown)
        setTimeout(() => {
            if (this.elOverlay.current) this.elOverlay.current.classList.add('showAnimation')
        }, 100)
    }

    componentWillUnmount() {
        this.body.classList.remove('overflowHidden')
        modalRoot.removeChild(this.el)
        window.removeEventListener('keydown', this.handleKeyDown)
    }

    handleKeyDown = e => {
        e.preventDefault()
        if (e.code === 'Escape') {
            this.props.onClose()
        }
    }

    handleBackdrop = e => {
        if (e.currentTarget === e.target) {
            this.props.onClose()
        }
    }

    render() {
        if (this.el) {
            return ReactDOM.createPortal(
                <div className={styles.overlay} onClick={this.handleBackdrop} ref={this.elOverlay}>
                    {this.props.children}
                </div>,
                this.el
            )
        } else {
            return null
        }
    }
}

export default Modal

Modal.propTypes = {
    children: PT.element,
    onClose: PT.func,
}
