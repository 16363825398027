import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import classNames from 'classnames'
import { navigate } from 'gatsby'

import ArrowIcon from 'src/components/SvgSprite/ArrowIcon'
import Picture from '../Picture'
import SvgSprite from '../SvgSprite'
import {
    caseCalatonStudioBranding,
    caseCalatonStudioWeb,
    caseCalatonSystemsWeb,
    caseCalatonSystemsBranding,
    caseLinguamillApp,
} from '../CasesPage/cases'

import styles from './styles.module.scss'

SwiperCore.use([EffectFade])

const buttons = [
    {
        title: 'Browse Case Studies',
        cases: [
            caseCalatonStudioBranding,
            caseLinguamillApp,
            caseCalatonStudioWeb,
            caseCalatonSystemsBranding,
            caseCalatonSystemsWeb,
        ],
    },
    {
        title: 'Web Design',
        cases: [
            caseCalatonStudioWeb,
            { img: '', alt: '', link: '' },
            { img: '', alt: '', link: '' },
            caseCalatonSystemsWeb,
            { img: '', alt: '', link: '' },
        ],
    },
    {
        title: 'Mobile Design',
        cases: [
            caseLinguamillApp,
            { img: '', alt: '', link: '' },
            { img: '', alt: '', link: '' },
            { img: '', alt: '', link: '' },
            { img: '', alt: '', link: '' },
        ],
    },
    {
        title: 'Branding',
        cases: [
            caseCalatonStudioBranding,
            { img: '', alt: '', link: '' },
            { img: '', alt: '', link: '' },
            caseCalatonSystemsBranding,
            { img: '', alt: '', link: '' },
        ],
    },
]

class Cases extends Component {
    constructor() {
        super()
        this.gridSlider = React.createRef()
        this.state = {
            activeIndex: 0,
            filter: null,
        }
    }

    btnActiveClassName = (className, i) =>
        classNames(className, { [styles.active]: this.state.activeIndex === i })

    changeSlide = index => this.gridSlider.current.swiper.slideTo(index)

    buttonsItemOnClickHandler = event => {
        const target = event.target
        const index = target.getAttribute('data-index')
        const filterCases = this.getFilterCases(+index)

        this.setState({ activeIndex: +index, filter: filterCases })

        this.changeSlide(index)
    }

    btnArrowIconClickHandler = () => {
        sessionStorage.setItem('filterCases', this.state.filter)
    }

    getFilterCases(index) {
        switch (index) {
            case 1:
                return 'branding'
            case 2:
                return 'webDesign'
            case 3:
                return 'mobileApp'
            default:
                return null
        }
    }

    render() {
        const parameters = {
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
        }

        const getSlideWithCases = cases => {
            return cases.map((item, i) => (
                <div
                    key={i}
                    className={styles.item}
                    data-href={item.link}
                    onClick={() => navigate(item.link)}
                >
                    {(item.img && (
                        <img src={item.img} srcSet={`${item.retina} 2x`} alt={item.alt} />
                    )) ||
                        (item.picture && <Picture pic={item.pictureBig || item.picture} />)}

                    {(item.img || item.pictureBig || item.picture) && (
                        <div className={styles.hoverOverlay}>
                            <h3
                                className={styles.hoverOverlayTitle}
                                dangerouslySetInnerHTML={{ __html: item.title }}
                            ></h3>
                            {item.description && (
                                <p className={styles.hoverOverlayText}>{item.description}</p>
                            )}
                            <button className={styles.viewProject}>View case</button>
                        </div>
                    )}
                    {!(item.img || item.pictureBig || item.picture) ? (
                        <div className={styles.placeholder}>
                            <div className={styles.textPlaceholder}>Coming soon</div>
                            <div className={styles.blur}>
                                <SvgSprite name="logoCalatonStudio"></SvgSprite>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ))
        }

        const casesWrapClass = classNames(styles.wrapper, 'container')

        return (
            <section id="cases" className={styles.cases}>
                <div className={casesWrapClass}>
                    <h2 className={styles.title}>Cases</h2>
                    <p className={styles.subTitle}>
                        Calaton Studio is committed to driving impact with best-in-class design.{' '}
                        <br />
                        Don’t just take it from us — check out the work we’ve done for our amazing
                        clients!
                    </p>
                    <div className={styles.buttons}>
                        {buttons.map(({ title }, i) => (
                            <button
                                className={this.btnActiveClassName(styles.buttonsItem, i)}
                                key={title}
                                data-index={i}
                                onClick={this.buttonsItemOnClickHandler}
                            >
                                {title}
                            </button>
                        ))}
                    </div>
                    <Swiper ref={this.gridSlider} className={styles.gridSlider} {...parameters}>
                        {buttons.map(({ title, cases }) => (
                            <SwiperSlide key={title} className={styles.grid}>
                                {getSlideWithCases(cases)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={styles.seeMore}>
                        <a href="/cases">
                            <ArrowIcon
                                onClick={this.btnArrowIconClickHandler}
                                className={styles.arrow}
                                arrow={{
                                    usual: 'arrowSecondary',
                                    hover: 'arrowSecondaryHover',
                                    disabled: 'arrowSecondaryDisabled',
                                }}
                            >
                                <span>See more</span>
                            </ArrowIcon>
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}

export default Cases
